<template>
  <div>
    <b-row class="match-height mb20">
      <b-col md="4">
        <b-card>
          <div class="auth-info-box">
            <h4>授权过期</h4>
            <div>
              <div class="mb10 mt20 f20">
                剩余天数
              </div>
              <strong
                class="f18"
                style="color: #f56c6c"
              >10</strong>
            </div>
            <div>
              <div class="mb10 mt20 f20">
                到期时间
              </div>
              <strong
                class="f18"
                style="color: #f56c6c"
              >2023-9-10</strong>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-card title="系统授权">
          <el-form
            ref="form"
            label-width="80px"
            :model="form"
          >
            <el-form-item label="机器码：">
              <div>{{ machineCode }}</div>
            </el-form-item>
            <el-form-item
              prop="LicenseCode"
              label="授权码："
              :rules="[ { required: true, message: '授权码不能为空', trigger: 'change' }]"
            >
              <el-input
                v-model="form.LicenseCode"
                placeholder="请输入密匙"
                type="textarea"
              />
            </el-form-item>
            <el-form-item
              label="上传文件"
              prop="fileName"
              :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]"
            >
              <input
                id="uploadFileInput"
                accept=".xls,.xlsx"
                type="file"
                style="display: none"
                @change="uploadFileInputChange"
              >
              <el-input
                v-model="form.fileName"
                disabled
                placeholder="请上传文件"
                class="upload-file-input"
              >
                <template slot="append">
                  <div
                    style="cursor: pointer;"
                    @click="uploadFileClick"
                  >
                    选择文件
                  </div>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height mb20">
      <b-col md="4">
        <b-card title="授权信息">
          <el-form
            label-width="80px"
          >
            <el-form-item label="软件版本：">
              <div>add</div>
            </el-form-item>
            <el-form-item label="软件版本：">
              <div>add</div>
            </el-form-item>
            <el-form-item label="软件版本：">
              <div>add</div>
            </el-form-item>
            <el-form-item label="软件版本：">
              <div>add</div>
            </el-form-item>
          </el-form>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-card title="联系我们">
          <el-form
            label-width="80px"
          >
            <el-form-item label="公司名称：">
              <div>add</div>
            </el-form-item>
            <el-form-item label="官网地址：">
              <div>add</div>
            </el-form-item>
            <el-form-item label="软件版本：">
              <div>add</div>
            </el-form-item>
            <el-form-item label="软件版本：">
              <div>add</div>
            </el-form-item>
          </el-form>
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>
<script>
export default {
  data() {
    return {
      form: {

      },
    }
  },
  created() {
  },
}
</script>
<style lang="scss">
  .f20{
    font-size: 20px;
  }
  .f18{
    font-size: 18px;
  }
</style>
